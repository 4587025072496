@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

/* Define the custom font using @font-face */
@font-face {
    font-family: 'aptos';
    src: url('/public/aptos-font/aptos-light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'aptos';
    src:url('/public/aptos-font/aptos-black.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'aptos';
    src:url('/public/aptos-font/aptos-italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}



/* Apply the font globally */
:root {
    font-family: 'aptos', sans-serif;
}

* {
	font-family: 'aptos', sans-serif;
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg); 
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}

.text-care{
	color: #89764b;
}

.bg-care{
	background-color: #89764b;
}

.bg-care-light {
	background-color: #a68b63;
  }
  
  .bg-care-dark {
	background-color: #5e4e31;
  } 

  .bg-blue-oxyera{
	background-color: #04899e;
  }

  .text-blue-oxyera{
	color: #04899e;
  }


.bg-custom-grey{
	background-color: #212121;
}

.bg-custom-lightgrey{
	background-color: #ededed;
}

.glassmorphism{
	background: rgba(0, 0, 0, 0.2);
	z-index: 1000;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(15px);
	-webkit-backdrop-filter: blur(30px);
	border: 0px solid rgba(255, 255, 255, 0.3);
}

.glassmorphism-white{
	background: rgba(255, 255, 255, 0.2);
	z-index: 1000;
	box-shadow: 0 4px 30px rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(15px);
	-webkit-backdrop-filter: blur(30px);
	border: 1px solid rgba(0, 0, 0, 0.3);
}

/* styles.css */
.confetti-canvas {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999; /* Ensure this is high enough to be on top of all other elements */
	pointer-events: none; /* Prevent interactions with the canvas */
}

.text-negative {
    color: rgb(255, 255, 255);
    display: block;
    filter: invert(0.4);
    mix-blend-mode: difference;
  }

  .text-negative-remove {
    color: black !important;
    display: block !important;
    filter: invert(0) !important;
    mix-blend-mode: normal !important;
  }

  /* Add this to your CSS file or inside a <style> tag */

.custom-select {
	position: relative;
	display: inline-block;
	width: 100%;
  }
  
  .custom-select select {
	width: 100%;
	padding: 10px;
	border: 1px solid #000000;
	border-radius: 4px;
	background: transparent;
	appearance: none; /* Removes the default arrow */
  }
  
  .custom-select::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 10px;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid currentColor; /* Arrow color inherits from parent */
	transform: translateY(-50%);
	pointer-events: none; /* Ensures the arrow doesn’t interfere with clicking */
  }

  /* Add this CSS to your global stylesheet or in a CSS module */

.scrollbar-hidden {
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* Internet Explorer and Edge */
  }
  
  .scrollbar-hidden::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
  }
  
  #game-container {
	position: relative;
	overflow: hidden;
	border: 2px solid #ccc;
	border-radius: 8px;
	background-color: #f8f8f8; /* light background for contrast */
  }
	 
	  
  .vt323-regular {
	font-family: "VT323", monospace;
	font-weight: 600;
	font-style: normal;
  }
  
  .game-over-screen {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	color: #ff0000; /* Bright red for visibility */
	font-size: 24px; /* Adjust the font size as needed */
	background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
	padding: 20px;
	border: 3px solid #ff0000; /* Border to emphasize the box */
	border-radius: 8px; /* Rounded corners */
  }

  .game-won-screen {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	color: #0dff00; /* Bright red for visibility */
	font-size: 24px; /* Adjust the font size as needed */
	background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
	padding: 20px;
	border: 3px solid #0dff00; /* Border to emphasize the box */
	border-radius: 8px; /* Rounded corners */
  }
  
  .text {
	display: block; /* Make each line a block element for better spacing */
  }
  